import { createTheme } from '@mui/material/styles';

import AeonikBold from 'assets/fonts/Aeonik-Bold.otf';
import AeonikRegular from 'assets/fonts/Aeonik-Regular.otf';
import RocGroteskMedium from 'assets/fonts/RocGrotesk-Medium.otf';
import RocGroteskRegular from 'assets/fonts/RocGrotesk-Regular.otf';

import { colors } from './colors';
import { fonts, variantMapping } from './fonts';

export const theme = createTheme({
  palette: {
    text: {
      primary: colors.darkGreen900,
      secondary: colors.darkGreen700,
    },
    ...colors,
  },
  typography: {
    fontFamily: 'RocGrotesk-Regular',
    ...fonts,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'RocGrotesk-Regular';
        font-style: normal;
        font-display: swap;
        src: local('RocGrotesk-Regular'), url(${RocGroteskRegular}) format('opentype');
      }
      @font-face {
        font-family: 'RocGrotesk-Medium';
        font-style: normal;
        font-display: swap;
        src: local('RocGrotesk-Medium'), url(${RocGroteskMedium}) format('opentype');
      }
      @font-face {
        font-family: 'Aeonik-Regular';
        font-style: normal;
        font-display: swap;
        src: local('Aeonik-Regular'), url(${AeonikRegular}) format('opentype');
      }
      @font-face {
        font-family: 'Aeonik-Bold';
        font-style: normal;
        font-display: swap;
        src: local('Aeonik-Bold'), url(${AeonikBold}) format('opentype');
      }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping,
      },
    },
  },
});

import configDev from './values/dev.config.json';
import configPrd from './values/prd.config.json';
import configStg from './values/stg.config.json';

export default class Config {
  public amplify!: unknown;
  public apiUrl!: string;
  public appVersion!: string;

  public static get(env?: string): Config {
    switch (env || Config.getEnvKey()) {
      case 'stage':
        return {
          ...configStg,
          appVersion: this.getValueFromEnvironmentVariables('APP_VERSION'),
        };
      case 'prod':
        return {
          ...configPrd,
          appVersion: this.getValueFromEnvironmentVariables('APP_VERSION'),
        };

      default:
        return {
          ...configDev,
          appVersion: this.getValueFromEnvironmentVariables('APP_VERSION'),
        };
    }
  }

  public static getEnvKey(): string {
    const envKey = process.env.REACT_APP_ENV;

    if (!envKey) {
      return 'dev';
    }
    return envKey;
  }

  private static getValueFromEnvironmentVariables(key: string): string {
    const value = process.env[key];
    if (!value) {
      return 'not-found';
    }
    return value;
  }
}

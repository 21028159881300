import React from 'react';

import Typography from 'components/Typography';

const Home = () => {
  return (
    <>
      <Typography variant="Title" marginBottom={2}>
        PIP
      </Typography>
    </>
  );
};

export default Home;

import React, { PropsWithChildren } from 'react';
import { Typography as BaseTypography, TypographyProps } from '@mui/material';
import { colors } from 'theme/colors';

interface Props extends TypographyProps {
  color?: keyof typeof colors;
}

const Typography: React.FC<PropsWithChildren<Props>> = ({
  color = 'primary',
  variant = 'DecorBody1',
  children,
  ...props
}) => {
  return (
    <BaseTypography variant={variant} color={color} {...props}>
      {children}
    </BaseTypography>
  );
};

export default Typography;

import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import Home from 'pages/Home';

import Layout from 'components/Layout';

import ProtectedRoutes from './components/ProtectedRoutes';
import { Routes } from './routes';

const Navigation = () => {
  return (
    <ReactRoutes>
      <Route element={<Layout />}>
        <Route path={Routes.Home} element={<Home />} />
        {/* <Route path={Routes.Login} element={<Login />} /> */}
        <Route element={<ProtectedRoutes />}>
          {/* <Route index path={Routes.User} element={<User />} /> */}
        </Route>
      </Route>
    </ReactRoutes>
  );
};

export default Navigation;
